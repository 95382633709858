import SenderIDTable from './senderidTable.vue';
import moment from 'moment';
import $ from 'jquery';
import store from '@/state/store.js';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Reserved Sender Ids',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    SenderIDTable
  },
  name: 'admin-ReservedSenderIDs',
  data() {
    return {
      loading: false,
      payload: {
        type: '',
        header: '',
        data: {}
      },
      success: '',
      error: '',
      loadingAction: false,
      formData: {
        reservedIDs: ''
      },
      rules: {
        reservedIDs: [{
          required: true,
          message: 'Please Sender ID is required.',
          trigger: 'change'
        }]
      },
      page: 1,
      pageSize: 20,
      total: 0,
      search: '',
      reservedIDs: []
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    submitAction(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loadingAction = true;
          this.success = '';
          if (this.payload.header == 'Edit Sender IDs') {
            let {
              row,
              $index
            } = this.payload.data;
            return store.dispatch('admin/ReservedSenderIDs/editSenderIDs', {
              reservedIDs: this.formData.reservedIDs,
              industry_id: row.id,
              key: $index
            }).then(res => {
              this.loadingAction = false;
              this.success = res;
            }).catch(err => {
              var _err$response;
              this.loadingAction = false;
              if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
                this.errors = "Network error try again.";
              }
            });
          } else {
            let payload = {};
            return store.dispatch('admin/ReservedSenderIDs/createSenderIDs', this.formData).then(message => {
              this.loadingAction = false;
              this.success = message;
            }).catch(err => {
              var _err$response2;
              this.loadingAction = false;
              if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
                this.errors = "Network error try again.";
              }
            });
          }
        } else {
          this.loadingAction = false;
          return false;
        }
      });
    },
    //     reserveAction() {
    //         let payloadData = {
    //         data: {
    //             status: this.payload.status,
    //             id: this.payload.data.id,
    //         },
    //         stateData: {
    //             index: this.payload.index,
    //             senderType: this.payload.senderType
    //         }
    //     }

    //     return store.dispatch('senderid/getSenderIDs', payloadData)
    //         .then(()=> {

    //             this.$message({
    //                 type:'success',
    //                 message:' Approved'
    //             })
    //             this.loading = false
    //             this.success = message
    //             }).catch(() => {

    //                 this.this.$message({
    //                     type:'error',
    //                     message:'There was an error'
    //                 })
    //         })

    // },
    hideModal() {
      $('#ReservedSenderIDs').modal('hide');
      this.error = '';
      this.success = '';
      this.loading = false;
      this.formData = {
        reservedIDs: ''
      };
    },
    openActionModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal('show');
    },
    openReservedSenderIdModal() {
      $('#ReservedSenderIDs').modal('show');
    },
    editSenderId(data) {
      this.payload = {
        type: 'edit',
        data: data,
        header: 'Edit Sender Id'
      };
      this.formData.reservedIDs = data.row.reservedIDs;
      this.openReservedIdModal();
    },
    deleteSenderId(data) {
      this.payload = {
        message: 'Are you sure you want to delete this Sender Id?',
        action: 'deleteSenderId',
        actionMessage: 'Delete',
        data: data,
        iconType: 'delete',
        index: data.$index
      };
      this.openActionModal();
    },
    openModal() {
      this.payload = {
        type: 'new',
        header: 'Create Reserved ID'
      };
      this.openReservedSenderIdModal();
    }
  },
  computed: {
    tableData() {
      return store.getters['admin/senderIDs/reservedSenderIds'];
    },
    searchReservedIDs() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData;
    },
    displayData() {
      return this.reservedIDs.filter(data => data.sender_id.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch('senderid/viewReservedSenderIDs').then(data => {
      this.reservedIDs = data.data.data;
    }).finally(() => this.loading = false);
  }
};